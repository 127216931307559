<script setup>
import { useAuthManager } from '@0x234/common-vue/composables/auth-manager';
import env from 'src/configs/env';
import { useAuthStore } from 'stores/auth-store';
import { computed, onBeforeMount } from 'vue';

const $props = defineProps({
    hideStrategies: {
        type: Array,
        required: false,
        default: []
    }
});

const authManager = useAuthManager();
const authStore = useAuthStore();

const visibleStrategies = computed(() => {
    return authStore.strategies.filter(s => !$props.hideStrategies.includes(s.id));
});

onBeforeMount(async () => {
    await authStore.loadStrategies();
});
</script>

<template>
    <div class="social-login">
        <div class="action-buttons q-mx-auto flex row items-center justify-center">
            <div v-for="s of visibleStrategies" v-if="visibleStrategies.length > 0" class="q-ma-xs">
                <q-btn
                    @click="authManager.openStrategyLogin(s, env.siteUsername)"
                    :icon="$q.dark.isActive && s.iconDark ? s.iconDark : s.icon"
                    :title="`Continue with ${s.name}`"
                    :class="[ 'q-px-sm btn-social', `btn-social-${s.id}` ]"
                    role="button"
                    no-caps
                    unelevated
                />
            </div>
            <template v-else>
                <q-skeleton v-for="() in Array(5)" type="QBtn" width="46px" height="46px" class="q-ma-xs"/>
            </template>
        </div>
    </div>
</template>

<style lang="scss">
.social-login .btn-social-email .q-icon {
    font-size: 26px;
    padding: 9px;
}
</style>
